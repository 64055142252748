// MemoModal.js

import React from 'react';
import './MemoModal.css'; // Import your CSS for modal styling

const MemoModal = ({ content, onClose }) => {
  return (
    <div className="memo-modal">
      <div className="memo-modal-content">
        <span className="close-btn" onClick={onClose}>&times;</span>
        <p>{content}</p>
      </div>
    </div>
  );
};

export default MemoModal;

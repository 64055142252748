import './Nav.css'

function Nav() {
    return (
        <nav className="main-nav">
<a href="#home">Motors</a> 
<a href="#articles">Gearboxes</a> 
<a href="#about">Body parts</a> 
<a href="#about">Interior parts</a> 


<a href="#top-deals">Electronics</a> 
<a href="#new-arrivals">Wheels</a> 
    
        </nav>
    );
};

export default Nav;
import React, { useState, useContext, useEffect } from 'react';
import AuthContext from '../AuthContext';
import { useNavigate, Link } from 'react-router-dom'; // Import Link from react-router-dom
import { Form, Button, Alert } from 'react-bootstrap';
import './LoginForm.css';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loginStatus, setLoginStatus] = useState(null);
    const { login } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await login(username, password);
            setLoginStatus('success');
            setUsername('');
            setPassword('');
        } catch (error) {
            console.error('Login failed:', error);
            setLoginStatus('error');
        }
    };

    useEffect(() => {
        setLoginStatus(null);
    }, [username, password]);

    useEffect(() => {
        if (loginStatus === 'success') {
            navigate('/');
        }
    }, [loginStatus, navigate]);

    const handleAlertClose = () => {
        setLoginStatus(null);
    };

    return (
        <div className="login-form">
            <h1>Login form</h1>
            <Form onSubmit={handleSubmit}>
                {loginStatus === 'error' && (
                    <Alert variant="danger" onClose={handleAlertClose}>
                        Login failed. Please try again.
                    </Alert>
                )}
                <Form.Group controlId="formUsername">
                    <Form.Control
                        type="text"
                        placeholder="Username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                    />
                </Form.Group>

                <Form.Group controlId="formPassword">
                    <Form.Control
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </Form.Group>

                <Button variant="primary" type="submit">
                    Login
                </Button>
              
                {/* Replace Button with Link */}
                <Link to="/register" className="register-link">
                    Create Account
                </Link>

                {loginStatus === 'success' && (
                    <Alert variant="success" onClose={handleAlertClose} dismissible>
                        Login successful! Redirecting...
                    </Alert>
                )}
            </Form>
        </div>
    );
};

export default Login;

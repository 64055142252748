// import crypto from 'crypto-browserify';
import { CognitoUserPool, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import AWS from 'aws-sdk';

AWS.config.update({
    region: 'eu-south-1',
    credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: 'eu-south-1:ae2347b7-780a-4cd1-8ee8-ae992833b8db',
    }),
});

const poolData = {
    UserPoolId: 'eu-south-1_uhmzPjtdD',
    ClientId: '57g1tkf8qlefjaa6ula9mq3001',
};





// awsConfig.js
const awsConfig = {
    Auth: {
        region: 'eu-south-1',
        userPoolId: 'eu-south-1_uhmzPjtdD',
        userPoolWebClientId: '57g1tkf8qlefjaa6ula9mq3001',
        identityPoolId: 'eu-south-1:ae2347b7-780a-4cd1-8ee8-ae992833b8db',
        oauth: {
            domain: 'your-domain.auth.your-region.amazoncognito.com',
            scope: ['email', 'openid'],
            redirectSignIn: 'http://localhost:3000/login', // Redirect URI after sign-in
            redirectSignOut: 'http://localhost:3000/logout', // Redirect URI after sign-out
            responseType: 'code' // or 'token', depending on your setup
        }
    }
};

export default awsConfig;



export const userPool = new CognitoUserPool(poolData);

export const dynamoDb = new AWS.DynamoDB.DocumentClient();

// Function to generate the secret hash
function generateSecretHash(username, clientId, clientSecret) {
    const message = username + clientId;
    const hmac = crypto.createHmac('sha256', clientSecret);
    return hmac.update(message).digest('base64');
}

// Function to authenticate a user
export function authenticateUser(username, password) {
    const clientSecret = '11s6qg7a1i65euq5ssigrpvas3vhmo4bv69vshd9q6486ic7st78';
    const authenticationDetails = new AuthenticationDetails({
        Username: username,
        Password: password,
        SecretHash: generateSecretHash(username, poolData.ClientId, clientSecret)
    });

    const userData = {
        Username: username,
        Pool: userPool
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (result) => {
            console.log('Login success:', result);
        },
        onFailure: (err) => {
            console.error('Login failure:', err);
        },
    });
}

import React, { useState, useEffect } from 'react';
import Pagination from 'react-bootstrap/Pagination';
import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { data } from './data2.js'; // Assuming data is imported from a file or defined somewhere
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';

function Contact() {
    const [search, setSearch] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(15);
    const [cartItems, setCartItems] = useState([]);

    useEffect(() => {
        // Load cart items from localStorage on component mount
        const storedCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
        console.log('Component mounted, storedCartItems:', storedCartItems);
        setCartItems(storedCartItems);
    }, []);

    useEffect(() => {
        // Save cart items to localStorage whenever cartItems change
        console.log('cartItems state updated, saving to localStorage:', cartItems);
        localStorage.setItem('cartItems', JSON.stringify(cartItems));
    }, [cartItems]);

    const handleSearch = (e) => {
        setSearch(e.target.value);
        setCurrentPage(1); // Reset current page when search changes
    };

    // Filtered items based on search criteria
    const filteredItems = data.filter((item) => {
        if (search.trim() === '') return true; // Return true for all items if search is empty
        // Check if any field contains the search text (case insensitive)
        return Object.values(item).some((value) =>
            typeof value === 'string' ? value.toLowerCase().includes(search.toLowerCase()) : false
        );
    });

    // Calculate pagination variables based on filtered items
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredItems.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // Function to add or remove item from the cart
    const toggleCartItem = (itemId) => {
        setCartItems(prevCartItems => {
            const updatedCartItems = prevCartItems.includes(itemId)
                ? prevCartItems.filter(id => id !== itemId)
                : [...prevCartItems, itemId];
            console.log('Updated cartItems after toggle:', updatedCartItems);
            return updatedCartItems;
        });
    };

    // Function to check if an item is in the cart
    const isInCart = (itemId) => cartItems.includes(itemId);

    // Count of items in cart
    const cartItemCount = cartItems.length;

    // Function to generate pagination items with a maximum of 10 pages
    const generatePaginationItems = () => {
        const pageItems = [];
        const maxVisiblePages = 5;
        const halfMaxVisiblePages = Math.floor(maxVisiblePages / 2);
        let startPage = Math.max(1, currentPage - halfMaxVisiblePages);
        let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

        if (totalPages > maxVisiblePages) {
            // Adjust startPage and endPage to ensure there are maxVisiblePages pages shown
            if (endPage - startPage + 1 < maxVisiblePages) {
                startPage = Math.max(1, endPage - maxVisiblePages + 1);
            }
        }

        for (let page = startPage; page <= endPage; page++) {
            pageItems.push(
                <Pagination.Item
                    key={page}
                    active={page === currentPage}
                    onClick={() => handlePageChange(page)}
                >
                    {page}
                </Pagination.Item>
            );
        }

        return pageItems;
    };

// Example function in React to save cart items
const saveCartItems = async () => {
    
    const username = localStorage.getItem('user');
    const cartItems = [/* array of cart items */];
    console.log(username);
    try {
      const response = await fetch('http://localhost:5000/api/saveCart', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, cartItems }),
      });
  
      const data = await response.json();
      console.log('Success:', data);
      // Handle success scenario in your React component
    } catch (error) {
      console.error('Error saving cart items:', error);
      // Handle error scenario in your React component
    }
  };
  

    return (
        <>
            <h1>Full text search on this page.</h1>
            <Container>
                <h1 className='text-center mt-4'>Part Catalogue</h1>
                <h5>Full Text Search</h5>

                <Form id='fullTextSearch'>
                    <InputGroup className='my-3'>
                        <Form.Control
                            name='searchField'
                            onChange={handleSearch}
                            value={search}
                            placeholder='Search parts'
                        />
                    </InputGroup>
                </Form>

                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Vendor</th>
                            <th>Part Ref.</th>
                            <th>Part Name</th>
                            <th>Price</th>
                            <th>Deal type</th>
                            <th>Private</th>
                            <th>Location</th>
                            <th>Model</th>
                            <th>In Cart</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems.map((item, index) => (
                            <tr
                                key={index}
                                className={isInCart(item.id) ? 'in-cart' : ''}
                                onClick={() => toggleCartItem(item.id)}
                            >
                                <td>{item.id}</td>
                                <td>{item.vendor}</td>
                                <td>{item.part_ref}</td>
                                <td>{item.part_name}</td>
                                <td>{item.price}</td>
                                <td>{item.deal_type}</td>
                                <td>{item.private}</td>
                                <td>{item.location}</td>
                                <td>{item.model}</td>
                                <td className='text-center'>
                                    {isInCart(item.id) && (
                                        <FontAwesomeIcon icon={faShoppingCart} className='cart-icon' />
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>

                {/* Pagination */}
                <Pagination className='justify-content-center'>
                    <Pagination.Prev
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                    />
                    {generatePaginationItems()}
                    <Pagination.Next
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                    />
                </Pagination>

                {/* Page index */}
                <div className='text-center mt-3'>
                    Page {currentPage} of {totalPages}
                </div>

                {/* Cart summary */}
                <div className='text-center mt-3'>
                    <h5>Cart Summary</h5>
                    <p>Total Items in Cart: {cartItemCount}</p>
                    <button onClick={saveCartItems} className='btn btn-primary'>Proceed to Cart</button>
                </div>
            </Container>
        </>
    );
}

export default Contact;

import React, { useState } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import axios from 'axios'; // Import axios for making HTTP requests
import './Register.css'; // Import CSS file for styling
import { useNavigate, Link } from 'react-router-dom'; 

const Register = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('http://localhost:5000/api/register', {
                username,
                password,
                email,
                // If referral is defined and retrieved from localStorage
                referral: localStorage.getItem('user') || '', 
            });

            if (response.data.success) {
                setSuccessMessage('User created successfully!');
                setUsername('');
                setPassword('');
                setEmail('');
            } else {
                setError(response.data.message || 'Failed to create user');
            }
        } catch (error) {
            setError('Failed to create user');
            console.error('Registration error:', error);
        }
    };

    return (
        <Form className="user-creation-form" onSubmit={handleFormSubmit}>
            {error && <Alert variant="danger">{error}</Alert>}
            {successMessage && <Alert variant="success">{successMessage}</Alert>}
            
            <Form.Group controlId="formUsername">
                {/* <Form.Label>Username</Form.Label> */}
                <Form.Control
                    type="text"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                />
            </Form.Group>

            <Form.Group controlId="formPassword">
                {/* <Form.Label>Password</Form.Label> */}
                <Form.Control
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
            </Form.Group>

            <Form.Group controlId="formEmail">
                {/* <Form.Label>Email</Form.Label> */}
                <Form.Control
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
            </Form.Group>

            <Button variant="primary" type="submit">
                Create User Account
            </Button>
            <Link to="/login" className="register-link">
                    Log-in
                </Link>
        </Form>
    );
};

export default Register;

import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loginError, setLoginError] = useState(null);


  useEffect(() => {
    const storedUser = localStorage.getItem('user')|| null;

    if (storedUser) {
      try {
        console.log("Current user=", storedUser);
        const parsedUser = storedUser;
        setUser(parsedUser);
      } catch (error) {
        console.error('Error parsing user data from localStorage:', error);
        localStorage.removeItem('user');
      }
    } else {
      console.log('No user data found in localStorage.');
    }
  }, []);

  const login = async (username, password) => {
    try {
      const response = await axios.post('http://localhost:5000/api/login', { username, password });
      if (response.data.success) {
        const userData = response.data.user;
        setUser(username); // Update local state
        console.log("User data saved: ",{userData}, response.data.user, username);
        localStorage.setItem('user', username); // Store in localStorage
        setLoginError(null); // Clear any previous login errors
      } else {
        setLoginError(response.data.message || 'Unknown error');
        setUser(null); // Clear user state
      }
      return response.data;
    } catch (error) {
      console.error('Login failed:', error);
      setLoginError('Failed to login. Please try again.');
      setUser(null); // Clear user state
      throw error;
    }
  };

  const logout = async () => {
    try {
      await axios.post('http://localhost:5000/api/logout'); // Clear server session/token
      setUser(null); // Clear user state
      localStorage.removeItem('user'); // Remove user data from localStorage
    } catch (error) {
      console.error('Logout failed:', error);
      setUser(null); // Clear user state on error
      localStorage.removeItem('user'); // Still attempt to clear localStorage
      throw error;
    }
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, loginError }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export default AuthContext;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Pagination from './Pagination'; // Assuming Pagination.js is in the same directory
import MemoModal from './MemoModal'; // Import MemoModal component
import AddDeal from './AddDeal'; // Import AddDeal component
import './PartsTable.css'; // Import your CSS file

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRecycle, faStickyNote } from '@fortawesome/free-solid-svg-icons';

const PartsTable = () => {
  const [parts, setParts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [partsPerPage, setPartsPerPage] = useState(10); // Initial parts per page
  const [partsPerPageOptions] = useState([20, 50, 100]); // Options for parts per page
  const [totalParts, setTotalParts] = useState(0); // Total number of parts
  const [modeFilter, setModeFilter] = useState('all'); // Initial mode filter ('all', 'buy', 'sell')
  const [showDetails, setShowDetails] = useState(false); // State to toggle details visibility
  const [expandedModel, setExpandedModel] = useState(null); // State to track expanded model
  const [memoContent, setMemoContent] = useState(''); // State for memo content
  const [showMemoModal, setShowMemoModal] = useState(false); // State to toggle memo modal

  const user = localStorage.getItem('user');

  useEffect(() => {
    fetchData();
  }, [currentPage, partsPerPage, modeFilter]);

  const fetchData = async () => {
    try {
      const response = await axios.get('http://localhost:5000/api/deals');
      filterParts(response.data);
      setTotalParts(response.data.length);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Filter parts based on mode filter
  const filterParts = (data) => {
    let filteredParts = data;
    if (modeFilter === 'import') {
      filteredParts = data.filter((part) => part.dealtype === 'import');
    } else if (modeFilter === 'export') {
      filteredParts = data.filter((part) => part.dealtype === 'export');
    }
    setParts(filteredParts);
  };

  // Calculate indexes for pagination
  const indexOfLastPart = currentPage * partsPerPage;
  const indexOfFirstPart = indexOfLastPart - partsPerPage;
  const currentParts = parts.slice(indexOfFirstPart, indexOfLastPart);

  // Change page
  const goToPage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Navigate to first page
  const goToFirstPage = () => {
    goToPage(1);
  };

  // Navigate to previous page
  const goToPreviousPage = () => {
    if (currentPage > 1) {
      goToPage(currentPage - 1);
    }
  };

  // Navigate to next page
  const goToNextPage = () => {
    if (currentPage < Math.ceil(totalParts / partsPerPage)) {
      goToPage(currentPage + 1);
    }
  };

  // Navigate to last page
  const goToLastPage = () => {
    goToPage(Math.ceil(totalParts / partsPerPage));
  };

  // Handle change in parts per page
  const handlePartsPerPageChange = (e) => {
    setPartsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to first page when changing parts per page
  };

  // Toggle mode filter
  const toggleModeFilter = () => {
    if (modeFilter === 'all') {
      setModeFilter('import');
    } else if (modeFilter === 'import') {
      setModeFilter('export');
    } else if (modeFilter === 'export') {
      setModeFilter('all');
    }
    setCurrentPage(1); // Reset to first page when toggling mode
  };

  // Toggle details visibility
  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  // Expand model text
  const expandModel = (model) => {
    setExpandedModel(model);
  };

  // Close model popup
  const closeModelPopup = () => {
    setExpandedModel(null);
  };

  // Open memo modal
  const openMemoModal = (content) => {
    setMemoContent(content);
    setShowMemoModal(true);
  };

  // Close memo modal
  const closeMemoModal = () => {
    setShowMemoModal(false);
  };

  // Get current date
  const currentDate = new Date().toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
  });

  const buyCount = parts.filter((part) => part.dealtype === 'import').length;
  const sellCount = parts.filter((part) => part.dealtype === 'export').length;
  const totalPrice = parts.reduce((acc, part) => acc + part.price, 0);
  const uniqueSellers = new Set(parts.filter((part) => part.dealtype === 'export').map((part) => part.creator)).size;
  const uniqueBuyers = new Set(parts.filter((part) => part.dealtype === 'import').map((part) => part.creator)).size;

  return (
    <div>
         {user === 'olya' && <AddDeal /> }
      <h1>Recent Deals List <b>{currentDate}</b></h1>

      {user === 'dima' && (
        <button onClick={toggleDetails} className="btn btn-primary" style={{ marginBottom: '10px' }}>
          {showDetails ? 'Hide Details' : 'Show Details'}
        </button>
      )}

      {showDetails && (
        <div className="details-block" style={{ marginBottom: '20px' }}>
          {buyCount > 0 && <p>Buy Parts: {buyCount}</p>}
          {sellCount > 0 && <p>Sell Parts: {sellCount}</p>}
          <p>Total: €{totalPrice.toLocaleString('de-DE')}</p>
          {uniqueSellers > 0 && <p>Unique Sellers: {uniqueSellers}</p>}
          {uniqueBuyers > 0 && <p>Unique Buyers: {uniqueBuyers}</p>}
        </div>
      )}

      <div style={{ marginBottom: '10px' }}>
        <button onClick={toggleModeFilter} className="btn btn-primary">
          {modeFilter === 'import' ? 'Import' : modeFilter === 'export' ? 'Export' : 'Both'}
        </button>
      </div>

      <table border="1">
        <thead>
          <tr>
            <th>ID</th>
            <th>Deal type</th>
            <th>Part Ref</th>
            <th>Price</th>
            <th>From</th>
            <th>Destination</th>
            <th>Model</th>
            <th>Used</th>
            <th>Ordered</th>
            <th>Delivery Date</th>
          </tr>
        </thead>
        <tbody>
          {currentParts.map((part) => (
            <tr key={part.id} style={{ backgroundColor: part.dealtype === 'export' ? 'BlanchedAlmond' : 'inherit' }}>
              <td>{part.id}</td>
              <td>{part.dealtype}</td>
              <td>{part.partref}</td>
              <td>{part.price}</td>
              <td>{part.fromlocation}</td>
              <td>{part.destination}</td>
              <td>
                {part.model.length > 10 ? (
                  <span className="expandable-text" onMouseEnter={() => expandModel(part.model)}>
                    {part.model.slice(0, 10)}{part.model.length > 10 && '...'}
                  </span>
                ) : (
                  part.model
                )}
                {expandedModel === part.model && (
                  <span className="memo-icon" onClick={() => openMemoModal(part.model)}>
                    <FontAwesomeIcon icon={faStickyNote} />
                  </span>
                )}
              </td>
              <td>{part.used ? <FontAwesomeIcon icon={faRecycle} /> : 'new'}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination controls */}
      <div style={{ marginTop: '20px' }}>
        <Pagination
          currentPage={currentPage}
          partsPerPage={partsPerPage}
          totalParts={totalParts}
          goToFirstPage={goToFirstPage}
          goToPreviousPage={goToPreviousPage}
          goToNextPage={goToNextPage}
          goToLastPage={goToLastPage}
        />
      </div>

      {/* Page number */}
      <div style={{ marginTop: '10px' }}>
        Page {currentPage} of {Math.ceil(totalParts / partsPerPage)}
      </div>

      {/* Memo Modal */}
      {showMemoModal && <MemoModal content={memoContent} onClose={closeMemoModal} />}

      {/* Conditionally render AddDeal component for user 'olya' */}
   
    </div>
  );
};

export default PartsTable;

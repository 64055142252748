// src/components/AuthenticatedContent.js
import React from 'react';
import { Authenticator, Button } from '@aws-amplify/ui-react';
import { Card } from 'react-bootstrap';
import UserAttributeForm from './UserAttributeForm';

const AuthenticatedContent = () => {
  return (
    <Authenticator>
      {({ signOut, user }) => (
        <main>
          <h2>Hello, {user.username}</h2>
          {/* <h3>User Attributes:</h3>
          <Card className="p-3">
            <ul>
              {user.attributes && Object.keys(user.attributes).map((key) => (
                <li key={key}>
                  <strong>{key}:</strong> {user.attributes[key]}
                </li>
              ))}
            </ul>
          </Card> */}
          <Button onClick={signOut} className="mt-3">Sign out</Button>
          {/* <UserAttributeForm /> */}
        </main>
      )}
    </Authenticator>
  );
};

export default AuthenticatedContent;

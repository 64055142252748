import './Nav.css'
import PartPostForm from "./PartPostForm";
import { Routes, Route, Link } from "react-router-dom";

function Nav() {
    return (
        <div><nav className="main-nav">

            <b><a href="/">About</a></b>
            <b><a href="/avparts">New Arrivals</a></b>
            <a href="#top-deals">Top Deals</a>
            <a href="#low-mileage">Low Mileage</a>
            <a href="#contact">Contact</a>
            <a href="#low-mileage">Sale</a>
            <a href="#lastchance">Last Chance</a>
            <b><a href="/request">Request Part</a></b>

        </nav>
        </div>
    );
};

export default Nav;

//// <a href="#home">Home</a> */
//<a href="#articles">Articles</a> 

import React, { useState, useEffect } from 'react';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import AWS from 'aws-sdk';

const ConfirmUnconfirmedUsers = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const cognitoUsers = await listUsers();
                setUsers(cognitoUsers);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching users:', error);
                setLoading(false);
            }
        };

        fetchUsers();
    }, []);

    const listUsers = () => {
        return new Promise((resolve, reject) => {
            const userPool = new CognitoUserPool({
                UserPoolId: 'eu-south-1_uhmzPjtdD',
                ClientId: '57g1tkf8qlefjaa6ula9mq3001',
            });

            const cognitoIdentityServiceProvider = new AWS.CognitoIdentityServiceProvider({
                apiVersion: '2016-04-18',
                region: 'eu-south-1', // Update with your AWS region
                credentials: {
                    accessKeyId: 'your-access-key-id',
                    secretAccessKey: 'your-secret-access-key',
                },
            });

            const params = {
                UserPoolId: 'eu-south-1_uhmzPjtdD',
                AttributesToGet: [
                    'username',
                    'email',
                    'email_verified',
                ],
                Filter: 'cognito:user_status=UNCONFIRMED', // Filter for unconfirmed users
            };

            cognitoIdentityServiceProvider.listUsers(params, (err, data) => {
                if (err) {
                    reject(err);
                    return;
                }

                const users = data.Users.map(user => ({
                    username: user.Username,
                    email: user.Attributes.find(attr => attr.Name === 'email').Value,
                    emailVerified: user.Attributes.find(attr => attr.Name === 'email_verified').Value === 'true',
                }));

                resolve(users);
            });
        });
    };

    return (
        <div>
            <h2>Unconfirmed Users</h2>
            {loading && <p>Loading...</p>}
            {!loading && (
                <table className="table">
                    <thead>
                        <tr>
                            <th>Username</th>
                            <th>Email</th>
                            <th>Confirmation Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map(user => (
                            <tr key={user.username}>
                                <td>{user.username}</td>
                                <td>{user.email}</td>
                                <td>{user.emailVerified ? 'Confirmed' : 'Unconfirmed'}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default ConfirmUnconfirmedUsers;

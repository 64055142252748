// src/components/UserAttributeForm.js
import React, { useState } from 'react';
import { Amplify } from 'aws-amplify';
import { Button, Form, Container, Alert } from 'react-bootstrap';

const UserAttributeForm = () => {
  const [usernameTag, setUsernameTag] = useState('');
  const [message, setMessage] = useState('');

  const handleUpdateAttribute = async (e) => {
    e.preventDefault();
    try {
      const user = await Amplify.currentAuthenticatedUser();
      await Amplify.updateUserAttributes(user, {
        'custom:usernameTag': usernameTag, // Update this key with your custom attribute key
      });
      setMessage('User attribute updated successfully');
    } catch (error) {
      setMessage(`Error updating user attribute: ${error.message}`);
    }
  };

  return (
    <Container className="mt-4">
      <Form onSubmit={handleUpdateAttribute}>
        <Form.Group controlId="formUsernameTag">
          <Form.Label>Username Tag</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your username tag"
            value={usernameTag}
            onChange={(e) => setUsernameTag(e.target.value)}
          />
        </Form.Group>
        <Button variant="primary" type="submit" className="mt-3">
          Update Attribute
        </Button>
      </Form>
      {message && <Alert className="mt-3">{message}</Alert>}
    </Container>
  );
};

export default UserAttributeForm;

import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../AuthContext'; // Adjust the import based on your actual context location
import logo from './logo1.png'; // Adjust the import based on your actual logo location
//import AuthContext from '../AuthContext';
import Messages from './Messages';
import './Heading.css';

const storedUser = localStorage.getItem('user')

const HomePage = (props) => {
  const { user, logout } = useAuth();

  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  return (
    <div className="containerX">
      
      <div className='left-block'>  <img src={logo} className="App-logo" alt="logo" />  </div>

      <div className='rblock'>     {user ? (
        <>

          <button onClick={handleLogout}>Log-out:  <b>{storedUser}</b></button>
          <Link to="/userprofile">Profile</Link>
          {/* <Link to="/userprofile/msg"> Inbox [0]</Link> */}
        {/* <Messages /> */}
        </>
      ) : (
        <Link to="/login">Login</Link>

      )}</div>
      
 
  
    </div>
  );
};

export default HomePage;

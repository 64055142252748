import React from 'react';
import './Footer.css'; // Import the CSS file for styling

function Footer() {
    const today = new Date();
    return (
        <div className="copyright">
    {/* <span><p>Made with love in <b>L'Aquila, Abruzzo</b></p></span> */}
            <a href="#"> Privacy Policy</a> <a href="#">Cookie Policy</a> <a href="#">Termini e Condizioni</a>
            <h5> ricambi.top © <b>{today.getFullYear()}</b> P.IVA 43249821-23   <b>67100, L'Aquila, Abruzzo</b> </h5> 
        </div>
    );
}

export default Footer;

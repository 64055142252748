import React, { useState } from 'react';
import axios from 'axios';
import './AddDeal.css'; // Import the CSS file

const AddDeal = () => {
  const [formData, setFormData] = useState({
    dealtype: 'export',
    partref: '',
    price: '',
    fromlocation: 'Italy',
    destination: 'RB',
    model: '',
    used: true,
  });
  const [notification, setNotification] = useState(null); // State for notification message

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://localhost:5000/api/deals', formData);
      console.log('Deal added:', response.data);
      setNotification('Deal added successfully!'); // Set notification message
      // Optionally reset form
      setFormData((prevData) => ({
        ...prevData,
        partref: '',
        price: '',
        ...prevData,
        ...prevData,
        ...prevData,
      }));
      setTimeout(() => setNotification(null), 3000); // Remove notification after 3 seconds
    } catch (error) {
      console.error('Error adding deal:', error);
      setNotification('Error adding deal. Please try again.'); // Set error message
      setTimeout(() => setNotification(null), 3000); // Remove notification after 3 seconds
    }
  };

  return (
    <div className="add-deal-container">
      <h2>Add Deal</h2>
      {notification && <p className="notification">{notification}</p>} {/* Display notification message */}
      <form onSubmit={handleSubmit}>
        <div>
          <label>Deal Type:</label>
          <select name="dealtype" value={formData.dealtype} onChange={handleChange} required>
            <option value="">Select deal type</option>
            <option value="import">Import</option>
            <option value="export">Export</option>
          </select>
        </div>
        <div>
          <label>Part Ref:</label>
          <input type="text" name="partref" value={formData.partref} onChange={handleChange} required />
        {/* </div>
        <div> */}
          <label>Price:</label>
          <input type="number" name="price" value={formData.price} onChange={handleChange} required />
        </div>
        <div>
          <label>From:</label>
          <input type="text" name="fromlocation" value={formData.fromlocation} onChange={handleChange} required />
        {/* </div>
        <div> */}
          <label>Destination:</label>
          <input type="text" name="destination" value={formData.destination} onChange={handleChange} required />
        </div>
        <div>
          <label>Model:</label>
          <input type="text" name="model" value={formData.model} onChange={handleChange} required />
        {/* </div>
        <div> */}
          <label>Used:</label>
          <input type="checkbox" name="used" checked={formData.used} onChange={handleChange} />
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default AddDeal;

import React from 'react';

const Pagination = ({
  currentPage,
  partsPerPage,
  totalParts,
  goToFirstPage,
  goToPreviousPage,
  goToNextPage,
  goToLastPage,
}) => {
  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === Math.ceil(totalParts / partsPerPage);

  return (
    <nav>
      <ul className="pagination">
        <li className={`page-item ${isFirstPage ? 'disabled' : ''}`}>
          <button onClick={goToFirstPage} className="page-link" disabled={isFirstPage}>
            First
          </button>
        </li>
        <li className={`page-item ${isFirstPage ? 'disabled' : ''}`}>
          <button onClick={goToPreviousPage} className="page-link" disabled={isFirstPage}>
            Previous
          </button>
        </li>
        <li className={`page-item ${isLastPage ? 'disabled' : ''}`}>
          <button onClick={goToNextPage} className="page-link" disabled={isLastPage}>
            Next
          </button>
        </li>
        <li className={`page-item ${isLastPage ? 'disabled' : ''}`}>
          <button onClick={goToLastPage} className="page-link" disabled={isLastPage}>
            Last
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;

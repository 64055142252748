import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// import DealsTable from './DealsTable';

const Home = () => {
  const [isEnglish, setIsEnglish] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('user') !== null);

  const toggleLanguage = () => {
    setIsEnglish(!isEnglish);
  };

  return (
    <div>
      <Link onClick={toggleLanguage}>
        {isEnglish ? 'Switch language' : 'Switch to English'}
      </Link>

      {isEnglish ? (
        <div>
          <h2>Welcome to our used car parts trading platform!</h2>
          <p>
            Our Marketplace designed specifically for car demolishing companies and <b>wholesale buyers</b>.
            Whether you're want find quality used automotive parts, or to streamline procurement processes  
            our platform offers comprehensive tools tailored to meet your needs.
          </p>
          <p>
            Explore our extensive catalog of parts, from engines to body panels, sourced from reputable suppliers. 
            Our intuitive interface and powerful search capabilities ensure you can find the exact components you need with ease.
          </p>
          <p>
            For sellers, our platform provides a robust marketplace to showcase your inventory to a targeted audience of wholesale buyers, facilitating quick and profitable transactions.
          </p>
          <p>
            Join us in revolutionizing the automotive parts industry with advanced technology and dedicated service. 
            <br></br><br></br>
            <b>Discover the benefits of partnering with us today. Welcome aboard!</b>
          </p>
        </div>
      ) : (
        <div>
          <h2>Добро пожаловать на наш маркетплейс б/у запчастей из Европы!</h2>
          <p>
            Мы специализируемся на обслуживании компаний по утилизации автомобилей в европе и оптовых покупателей из стран СНГ.
            На нашей платформе вы найдете инструменты для упрощения процессов закупок и поиска качественных б/у автомобильных запчастей.
          </p>
          <p>
            Мы предлагаем обширный каталог качественных деталей, начиная от двигателей до кузовных панелей, поставляемых от проверенных поставщиков.
          </p>
          <p>
            Наш интуитивно понятный интерфейс и мощные возможности поиска обеспечивают быстрый доступ к нужным компонентам.
          </p>
          <p>
            Для продавцов мы предлагаем мощную торговую платформу для представления вашего ассортимента целевой аудитории оптовых покупателей, способствующую быстрым и выгодным сделкам.
          </p>
          <p>
            <b>Откройте для себя преимущества сотрудничества с нами уже сегодня!</b>
          </p>
        </div>
      )}

{isLoggedIn && (
        <div>
         <button>Current  Catalog</button> 
         <button>Request a Part</button> 
         <br></br>
         <button>Add Part for Sale</button> 
         <button>Contact Us</button> 
        </div>
      
      )}
{/* <DealsTable /> */}

      {!isLoggedIn && (
        <div>
          <button>Sign-in</button>
          <button>Register</button>


          {/* leave request and your contacts if you are not find what you need/  an we will contact you as soon as possible */}
        </div>
      
      )}
    </div>
  );
};

export default Home;

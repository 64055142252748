import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../AuthContext'; // Adjust the import based on your actual context location
import logo from './logo1.png'; // Adjust the import based on your actual logo location
import AuthContext from '../AuthContext';
import './UserProfile.css'; // Import the CSS file

const HomePage = (props) => {
  const { user } = useAuth();
  const [referralCount, setReferralCount] = useState(0);
  const [partsForSale, setPartsForSale] = useState(0);
  const [partsRequest, setPartsRequest] = useState(0);
  const storedUser = localStorage.getItem('user');

  useEffect(() => {
    fetchReferralCount();
    fetchPartsStats();
  }, []);

  const fetchReferralCount = async () => {
    try {
      const response = await axios.get('http://localhost:5000/api/referrals', {
        params: { referral: storedUser },
      });
      setReferralCount(response.data.count_rows);
    } catch (error) {
      console.error('Error fetching referral count:', error);
    }
  };

  const fetchPartsStats = async () => {
    try {
      const response = await axios.get('http://localhost:5000/api/parts/stats', {
        params: { user: storedUser },
      });
      setPartsForSale(response.data.partsForSale);
      setPartsRequest(response.data.partsRequest);
    } catch (error) {
      console.error('Error fetching parts stats:', error);
    }
  };

  return (
    <div className="containerZ">
      <p>Username: <b>{storedUser}</b></p>

  
                <label for="Email">Email:</label>
                <input type="text" id="partref" name="partref" required/>
           

      
      <hr></hr>
      <p>Parts for Sale: <b>{partsForSale}</b></p>
      <p>Parts Request: <b>{partsRequest}</b></p>
      <hr></hr>
      <br></br>
      <p><label for="Email">Location:</label>
                <textarea id="Location" name="Location" value={"Italy, Abruzzo, L'Aquila, 67100"} required/>
        
        
        
        </p>
      <p>Pricing plan: <b>Unlimited</b></p>
      <p>Balance: <b>18889 Eur.</b></p>
      <button>TopUp</button>
      {/* <br></br><br></br> */}
      <hr></hr>
      <p>Roles: <b>Admin, Buyer, Seller</b></p>
      <p>Referrals: <b>{referralCount}</b></p>
      <p>Referred by: </p>
    </div>
  );
};

export default HomePage;

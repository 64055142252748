import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Button, Container } from 'react-bootstrap';
import './Request.css'; // Use Request.css for styling

const PartForm = () => {
  const storedUser = localStorage.getItem('user');

  // Hooks should always be called at the top level
  const [formData, setFormData] = useState({
    part_ref: '',
    part_name: '',
    model: '',
    comment: '',
    price: '',
    creator: storedUser || '', // Set creator from localStorage
    deal_type: 'sell',
  });

  const initialFormData = {
    part_ref: '',
    part_name: '',
    model: '',
    comment: '',
    price: '',
    creator: storedUser || '', // Set creator from localStorage
    deal_type: 'sell',
  };

  // Optionally, you can use useEffect to set the creator value when the component mounts
  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      creator: storedUser || '',
    }));
  }, [storedUser]);

  // Check if user is not logged in and redirect to login page
  if (!storedUser) {
    window.location.href = '/login'; // Redirect to login page
    return null; // Render nothing if redirecting
  }

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Ensure the creator is set just before submission
    const dataToSubmit = { ...formData, creator: storedUser || '' };
    try {
      const response = await axios.post('http://localhost:5000/api/parts', dataToSubmit);
      console.log('Data submitted successfully:', response.data);
      setFormData(initialFormData);
    } catch (error) {
      console.error('Error submitting data:', error);
    }
  };

  return (
    <Container className="request-form-container">
      <h3 className="mt-4">Add Part for Sale Form</h3>
      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Label className="form-label text-left">Part Ref.</Form.Label>
          <Form.Control
            type="text"
            name="part_ref"
            value={formData.part_ref}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Label className="form-label text-left">Price. Eur</Form.Label>
          <Form.Control
            type="text"
            name="price"
            value={formData.price}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.Label className="form-label text-left">Part Name</Form.Label>
          <Form.Control
            type="text"
            name="part_name"
            value={formData.part_name}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label className="form-label text-left">Car Model</Form.Label>
          <Form.Control
            type="text"
            name="model"
            value={formData.model}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label className="form-label text-left">Comment</Form.Label>
          <Form.Control
            type="text"
            name="comment"
            value={formData.comment}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group className="d-none">
          <Form.Label className="form-label">Creator</Form.Label>
          <Form.Control
            type="text"
            name="creator"
            value={formData.creator}
            readOnly
          />
        </Form.Group>
        <Form.Group className="d-none">
          <Form.Label className="form-label">Deal Type</Form.Label>
          <Form.Control
            type="text"
            name="deal_type"
            value={formData.deal_type}
            readOnly
          />
        </Form.Group>
        <Button variant="primary" type="submit" className="mt-3">
          Submit
        </Button>
      </Form>
    </Container>
  );
};

export default PartForm;

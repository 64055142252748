import React, { useState } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';
import { userPool, dynamoDb } from './awsConfig';
import { CognitoUserAttribute } from 'amazon-cognito-identity-js';
import './Register.css';

const RegisterAws = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const navigate = useNavigate();

    const handleFormSubmit = (e) => {
        e.preventDefault();
        setError(null);
        setSuccessMessage('');

        const attributeList = [
            new CognitoUserAttribute({ Name: 'email', Value: email }),
        ];

        userPool.signUp(username, password, attributeList, null, async (err, result) => {
            if (err) {
                setError(err.message || 'Failed to create user');
                return;
            }

            const userId = result.userSub; // Cognito User ID
            const referral = localStorage.getItem('user') || '';
            const params = {
                TableName: 'user_xcis',
                Item: {
                    userId,
                    username,
                    email,
                    referral,
                },
            };

            try {
                await dynamoDb.put(params).promise();
                setSuccessMessage('User created successfully!');
                setUsername('');
                setPassword('');
                setEmail('');
            } catch (dynamoErr) {
                setError('Failed to store user data');
                console.error('DynamoDB error:', dynamoErr);
            }
        });
    };

    return (
        <Form className="user-creation-form" onSubmit={handleFormSubmit}>
            {error && <Alert variant="danger">{error}</Alert>}
            {successMessage && <Alert variant="success">{successMessage}</Alert>}

            <Form.Group controlId="formUsername">
                <Form.Control
                    type="text"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                />
            </Form.Group>

            <Form.Group controlId="formPassword">
                <Form.Control
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
            </Form.Group>

            <Form.Group controlId="formEmail">
                <Form.Control
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
            </Form.Group>

            <Button variant="primary" type="submit" className="red-button">
                Create User Account on AWS
            </Button>
            <Link to="/login" className="register-link">
                Log-in
            </Link>
        </Form>
    );
};

export default RegisterAws;

// src/components/BuySellButtons.js
import React from 'react';
import { ButtonGroup, Button } from 'react-bootstrap';

const BuySellButtons = (props) => {
  const handleBuy = () => {
    // Handle logic for Buy button click
    console.log('Buy button clicked');
  };

  const handleSell = () => {
    // Handle logic for Sell button click
    console.log('Sell button clicked');
  };

  return (
    <div>
      <h1>{props.view_name}</h1>
      <ButtonGroup>
        <Button variant="success" onClick={handleBuy}>Buyer</Button>
        <Button variant="danger" onClick={handleSell}>Seller</Button>
      </ButtonGroup>
    </div>
  );
};

export default BuySellButtons;

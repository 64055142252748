import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import './App.css';
import CatalogSearch from './components/CatalogSearch';
import Heading from './components/Heading';
import Nav from './components/Nav';
import Footer from './components/Footer';
import MainNavBar from './components/MainNavBar';
//import { data } from './components/data.js';
import './bootstrap.min.css';
import PartsTable from './components/PartsTable';
import PartPostForm from './components/PartPostForm';
import LookFor from './components/Request';
import BuySellButtons from './components/BuySellButtons';
import Register from './components/Register';
import RegisterAws from './components/RegisterAws';
import Home from './components/Home';
import Login from './components/Login';
import Logout from './components/Logout';
import UserList from './components/UserList';
import { AuthProvider } from './AuthContext'; // Assuming this is where AuthProvider is exported
import AuthGuard from './components/AuthGuard';
import UserProfile from './components/UserProfile';
import DealsTable from './components/DealsTable';
import { Authenticator, Button } from '@aws-amplify/ui-react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import AuthenticatedContent from './components/AuthenticatedContent'; // Import the new component


//import UserContext from "./components/AccountContext";  //clean 
import Views from "./components/Views";   // clean
import ConfirmUnconfirmedUsers from './components/ConfirmUnconfirmedUsers';
// import AuthAPP from './components/AuthAPP';

function App() {
 // const [errorMessage, updateErrorMessage] = useState(null);
 // const [searchQuery, setSearchQuery] = useState('');
//  const [contacts, setContacts] = useState(data);
//  const [search, setSearch] = useState('');

  //const [currentPage, setCurrentPage] = useState(1);
  //const [itemsPerPage] = useState(10);

 // const indexOfLastItem = currentPage * itemsPerPage;
  //const indexOfFirstItem = indexOfLastItem - itemsPerPage;
 // const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  //const paginate = (pageNumber) => setCurrentPage(pageNumber);

 //const handleSearch = (query) => {
 //   setSearchQuery(query);
 //   console.log('Search Query:', query);
    // Implement your search logic here
  //};
  // const { user, signOut } = useAuthenticator((context) => [context.user]);

  return (

     
    <AuthProvider> {/* Wrap entire application with AuthProvider */}
      <Router>
        <div className="App">
          <Nav /> {/* Navigation bar */}
          <Heading /> {/* Welcome bar */}
          <AuthenticatedContent /> 
          <MainNavBar /> {/* Main navigation bar */}
          <nav>
            <Link to="/" className="nav-item">
              Homepage
            </Link>
            <Link to="/register" className="nav-item">
              ...Register-Local..
            </Link>
            <Link to="/registerAWS" className="nav-itemAWS">
              RegisterAWS
            </Link>
            <Link to="/avparts" className="nav-item">
              Freshly Added
            </Link>
            <Link to="/catalog" className="nav-item">
              Catalog
            </Link>
            <Link to="/sell" className="nav-item">
              Sell
            </Link>
            <Link to="/request" className="nav-item">
              Request a part
            </Link>
            <Link to="/role" className="nav-item">
              Change Role
            </Link>
            <Link to="/login" className="nav-item">
              Login
            </Link>
            <Link to="/logout" className="nav-item">
              Logout
            </Link>
            <Link to="/users" className="nav-item">
              Users list
            </Link>
            <Link to="/deals" className="nav-item">
              Recent Deals
            </Link>
            <Link to="/confirm" className="nav-itemAWS">
              Confirm Users
            </Link>
     
          </nav>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/register" element={<Register />} />
            <Route path="/registerAWS" element={<RegisterAws />} />

            <Route path="/avparts" element={<PartsTable />} />
            <Route path="/catalog" element={<CatalogSearch />} />
            <Route path="/sell" element={<PartPostForm />} />
            <Route path="/request" element={<LookFor />} />
            <Route path="/role" element={<BuySellButtons view_name="Change Role" />} />
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/users" element={<UserList />} />
            <Route path="/userprofile" element={<UserProfile />} />
            
            <Route path="/deals" element={<DealsTable />} />
            <Route path="/confirm" element={<ConfirmUnconfirmedUsers />} />
          
            {/* <Route path="/add_deals" element={<DealsTable />} /> */}

          </Routes>

        

          <div style={{ color: 'red' }}>
            {/* Main clear Views:
            <Views /> */}
          </div>

          <Footer />
        </div>
      </Router>
    </AuthProvider>

  );
}

export default App;
